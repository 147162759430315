<template>
  <div style="height: 100%">
    <!-- <elt-title :size="24" icon="icon-bookmark" title="浏览器书签" /> -->
		<elt-header title="浏览器书签" icon="icon-brush" />
    <!-- <elt-navi :nav="this.navBar" @switch="switchTab" /> -->
		<div class="content">
			<div class="flex" style="">
				<div v-for="(item, index) in navBar" @click="switchTab(item, index)" style="margin-right: 32px;" class="flex column cross-center">
					<span style="font-size: 22px; user-select: none;" :style="{color: current == index ? '#3761E1' : '#FFE5AA' }">{{item.title}}</span>
					<div style="height: 4px; margin: 8px; width: 24px; border-radius: 4px;" :style="{'background-color': current == index ? '#3761E1' : '' }"></div>
				</div>
			</div>

			<div style="margin-top: 20px; height: calc( var(--content-height) - var(--title-height) - var(--navi-height) - 20px );">

				<div v-for="item in links" class="element">
					<div style="color: #A1A1A1; font-size: 18px;">{{item.groupName}}</div>
					<div class="" style="display: grid; grid-template-columns: repeat(11, calc( calc(100% - 240px) / 11 ) ); grid-gap: 24px; margin-top: 32px;">
						<div class="flex column main-center cross-center" @click="jump(ele.url)" v-for="ele in item.group" :title="ele.desc" style="cursor: pointer;  ">
							<img style="width: 32px; height: 32px;" :src="ele.icon || 'https://cdn.jsdelivr.net/gh/vhhgx/common/images/icons/favicon.ico'" alt="">
							<span style="font-size: 15px; margin-top: 20px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; color: #fff;">{{ele.title}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      links: [],
      current: 0,
      navBar: [
        {title: '常用链接', tag: 'commonly'},
        {title: '设计', tag: 'design'},
      ],
    }
  },
  created() {
    this.getLinks(this.navBar[0].tag)
  },
  mounted() {},
  components: {},
  methods: {
    jump(url) { window.open(url, '_blank') },
    getLinks(tag) {
      let url = process.env.BASE_URL + `data/fav/${tag}.json`
      axios.get(url).then( res => {
        this.links = res.data
      })
    },
    switchTab(item, idx) {
      this.getLinks(item.tag)
      this.current = idx
    }
  }
}
</script>

<style>
/* .content { width: 100%; overflow-y: auto; }
.content::-webkit-scrollbar{ display: none; } */
.element { margin-top: 16px; margin-bottom: 32px; }
.element:last-child { margin-bottom: 0px; }
.element:first-child { margin-top: 0px;}
</style>